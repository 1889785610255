import React, {useState} from 'react';
import NavigationItems from "../navigationItems";
import {Link} from "gatsby";

const Sidebar = () => {
    const [location] = useState(typeof window !== 'undefined' && window.location.pathname);

    return (
        <div className='sidebar noselect'>
            {
                NavigationItems().map((item, index) => {
                    return (
                        <div key={index}>
                            <Link to={item.link[0]}>
                                <div className={`item${item.link.includes(location) ? ' active' : ''}`}>
                                    <div className='dot' />{item.title}
                                </div>
                            </Link>
                            <div className='submenu-wrapper'>
                                {
                                    item.submenu.active && item.submenu.active.map((item, index) => {
                                        return (
                                            <Link key={index} to={item.link[0]}>
                                                <div
                                                    className={`submenu-item${item.link.includes(location) ? ' active' : ''}`}>
                                                    <div className='dot' />{item.title}
                                                </div>
                                            </Link>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default Sidebar;