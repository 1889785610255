import React, {useEffect, useState} from 'react';
import Sidebar from "../components/Layout/Navigation/Sidebar/Sidebar";
import {Helmet} from "react-helmet";
import Navigation from "../components/Layout/Navigation/Navigation";
import Footer from "../components/Layout/Footer";
import Auth from '../components/Parents/Auth/index';
import '../../translation';
import { useTranslation } from 'react-i18next';
import AuthService from "../services/auth.service";
import ParentsService from '../services/parents.service';
import NewsEcole from './../assets/images/espace-parents/accueil/news-ecole.jpg';
import AlbumPhotos from './../assets/images/espace-parents/accueil/album-photos.jpg';
import Chinois from './../assets/images/espace-parents/news/inscription-activites-chinois.webp';
import Danse from './../assets/images/espace-parents/news/inscription-activites-danse.webp';
import Drums from './../assets/images/espace-parents/news/inscription-activites-drums.webp';
import Echecs from './../assets/images/espace-parents/news/inscription-activites-echec.webp';
import Escrime from './../assets/images/espace-parents/news/inscription-activites-escrime.webp';
import GeekSchool from './../assets/images/espace-parents/news/inscription-activites-geek-school.webp';
import Golf from './../assets/images/espace-parents/news/inscription-activites-golf.webp';
import Gym from './../assets/images/espace-parents/news/inscription-activites-gym.webp';
import Judo from './../assets/images/espace-parents/news/inscription-activites-judo.webp';
import PianoViolon from './../assets/images/espace-parents/news/inscription-activites-piano-violon.webp';
import Rugby from './../assets/images/espace-parents/news/inscription-activites-rugby.webp';
import Theatre from './../assets/images/espace-parents/news/inscription-activites-theatre.webp';
import Yoga from './../assets/images/espace-parents/news/inscription-activites-yoga.webp';
import Zumba from './../assets/images/espace-parents/news/inscription-activites-zumba.webp';
import Tennis from './../assets/images/espace-parents/news/inscription-activites-tennis.png';
import Albums from "../../../eibcagnes.fr/src/components/Parents/Albums";
const apiURL = process.env.GATSBY_API_URL;

const schoolName = 'eibpegomas';
const Parents = () => {
    const { t } = useTranslation();
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [])

    /* ----------------- AUTHENTICATION -------------------- */
    useEffect(() => {
        let mounted = true;

            mounted && AuthService.checkTokenParents()
                .then(() => {
                    mounted && setIsAuthenticated(true)
                })
                .catch(() => mounted && setIsAuthenticated(false))
        return () => mounted = false;

    },[])
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const Disconnect = () => {
        AuthService.logout();
        setIsAuthenticated(false);
    }

    /* --------------------- NEWS SECTION (DOCUMENTS) ------------------- */
    const [availableDocuments, setAvailableDocuments] = useState({
        ecole: [],
        activitesExtrascolaires: {
            informations: [],
        }
    });

    const [documentsEcole, setDocumentsEcole] = useState([
        {name: 'calendrier-scolaire', title: 'Calendrier Scolaire', link: ''},
        {name: 'evenements-annuels', title: 'Événements annuels', link: ''},
        {name: 'activites-extra-scolaires', title: 'Activités extra scolaires', link: ''},
        {name: 'planning-activites', title: 'Planning des activités', link: ''},
        {name: 'menu-cantine', title: 'Menu de la cantine', link: ''},

    ])

    const [documentsAES, setDocumentsAES] = useState([
        { name:'chinois', inscriptionLink: '', informationsLink: '', title: 'CHINOIS', img: Chinois},
        { name:'danse', inscriptionLink: '', informationsLink: '', title: 'DANSE', img: Danse},
        { name:'echecs', inscriptionLink: '', informationsLink: '',title: 'ECHECS', img: Echecs},
        { name:'escrime', inscriptionLink: '', informationsLink: '',title: 'ESCRIME', img: Escrime},
        { name:'eveil', inscriptionLink: '', informationsLink: '',title: 'GYM D\'ÉVEIL', img: Gym},
        { name:'geekschool', inscriptionLink: '', informationsLink: '',title: 'GEEK SCHOOL', img: GeekSchool},
        { name:'judo', inscriptionLink: '', informationsLink: '',title: 'JUDO', img: Judo},
        { name:'violon-piano', inscriptionLink: '', informationsLink: '',title: 'VIOLON-PIANO', img: PianoViolon},
        { name:'yoga-for-kids', inscriptionLink: '', informationsLink: '',title: 'YOGA for KIDS', img: Yoga},
        { name:'zumba-kids', inscriptionLink: '', informationsLink: '',title: 'ZUMBA KIDS', img: Zumba},
    ])

    const [documentsHAPPY, setDocumentHAPPY] = useState([
        { name:'baby-drums', inscriptionLink: '', informationsLink: '', title: 'BABY DRUMS', img: Drums},
        { name:'golf', inscriptionLink: '', informationsLink: '', title: 'GOLF', img: Golf},
        { name:'rugbytots', inscriptionLink: '', informationsLink: '', title: 'RUGBYTOTS', img: Rugby},
        { name:'tennis', inscriptionLink: '', informationsLink: '', title: 'TENNIS', img: Tennis},
        { name:'theatre', inscriptionLink: '', informationsLink: '', title: 'THEATRE', img: Theatre},
        { name:'zumba-happyland', inscriptionLink: '', informationsLink: '', title: 'ZUMBA HAPPYLAND', img: Zumba},

    ])

    const [section, setSection] = useState('');
    const goBack = () => {
        setSection('');
    }

    const setCustomSection = async(section) => {
        if (section === 'news') {
            // GET DOCUMENTS ECOLE
            await ParentsService.getAvailableDocuments(schoolName, 'ecole')
                .then((res) => {
                    if (res?.data?.links) {
                        let tab = res.data.links.map((link, index) => {
                            for (let i = 0; i < documentsEcole.length; i++){
                                if (link.includes(documentsEcole[i]?.name))
                                    return {
                                        title: documentsEcole[i].title,
                                        link: link,
                                        name: documentsEcole[i].name
                                    }
                            }
                        })
                        setDocumentsEcole(tab);
                    }
                })
            // GET DOCUMENTS ACTIVITES EXTRA
           await ParentsService.getAvailableDocuments(schoolName, 'activites-extrascolaires')
               .then((res) => {
                   let links = res?.data?.links;
                   let tab = [];
                   if (links) {
                       // FILL INSCRIPTION LINK IN TAB
                       for (let i = 0; i < links.length; i++){
                           for (let j = 0; j < documentsAES.length; j++){
                               if (links[i].includes(documentsAES[j]?.name) && links[i].includes('inscription'))
                                   tab.push({
                                       ...documentsAES[j],
                                       inscriptionLink: links[i]
                                   })
                           }
                       }
                       // FILL INSCRIPTION EMPTY
                       for (let i = 0; i < documentsAES.length; i++){
                           let findName = false;
                           for (let j = 0; j < tab.length; j++){
                               if (tab[j].name === documentsAES[i]?.name)
                                   findName = true;
                           }
                           if (!findName)
                               tab.push({...documentsAES[i],})
                       }
                       // FILL INFORMATIONS LINK IN TAB
                       for (let i = 0; i < links.length; i++){
                           for (let j = 0; j < documentsAES.length; j++){
                               if (links[i].includes(documentsAES[j]?.name) && links[i].includes('informations'))
                                   for (let h = 0; h < tab.length; h++){
                                       if (tab[h].name === documentsAES[j]?.name) {
                                           tab[h].informationsLink = links[i]
                                       }
                                   }
                           }
                       }
                       let oneLink = false;
                       for (let i = 0; i < tab.length; i++){
                           if (tab[i].informationsLink || tab[i].inscriptionLink)
                               oneLink = true
                       }
                       setDocumentsAES(oneLink ? tab : [])

                   }
               })
            // GET DOCUMENTS HAPPY LAND
            await ParentsService.getAvailableDocuments(schoolName, 'activites-happyland')
                .then((res) => {
                    let links = res?.data?.links;
                    let tab = [];
                    if (links) {
                        // FILL INSCRIPTION LINK IN TAB
                        for (let i = 0; i < links.length; i++){
                            for (let j = 0; j < documentsHAPPY.length; j++){
                                if (links[i].includes(documentsHAPPY[j].name) && links[i].includes('inscription'))
                                    tab.push({
                                        ...documentsHAPPY[j],
                                        inscriptionLink: links[i]
                                    })
                            }
                        }
                        // FILL INSCRIPTION EMPTY
                        for (let i = 0; i < documentsHAPPY.length; i++){
                            let findName = false;
                            for (let j = 0; j < tab.length; j++){
                                if (tab[j].name === documentsHAPPY[i].name)
                                    findName = true;
                            }
                            if (!findName)
                                tab.push({...documentsHAPPY[i],})
                        }
                        // FILL INFORMATIONS LINK IN TAB
                        for (let i = 0; i < links.length; i++){
                            for (let j = 0; j < documentsHAPPY.length; j++){
                                if (links[i].includes(documentsHAPPY[j].name) && links[i].includes('informations'))
                                    for (let h = 0; h < tab.length; h++){
                                        if (tab[h].name === documentsHAPPY[j].name) {
                                            tab[h].informationsLink = links[i]
                                        }
                                    }
                            }
                        }
                        let oneLink = false;
                        for (let i = 0; i < tab.length; i++){
                            if (tab[i].informationsLink || tab[i].inscriptionLink)
                                oneLink = true
                        }
                        oneLink ? setDocumentHAPPY(tab) : setDocumentHAPPY([])

                    }
                })
        }
        setSection(section);
    }


    return (
        <>
            <Navigation />
            <div className='container-custom page d-flex'>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Parents | Collège Lycée Lafayette Nice</title>
                    <meta name="description" content="Le Collège Lycée Lafayette offre cursus bilingue et pluridisciplinaire : éducation bi-culturelle, une pédagogie différenciée, la confiance et l'estime de soi." />
                </Helmet>
                <Sidebar />
                <div className='content fadeInEarth'>
                    <div className='special-title'>
                            Espace Parents
                        </div>
                    {isAuthenticated
                        ?

                                    section ? <div className='row mb-4'>
                                                <div className='col-6'>
                                                  <button className='btn btn-danger w-100' onClick={Disconnect}>Déconnexion</button>
                                                </div>
                                                <div className='col-6'>
                                                    <button className='btn btn-info w-100' onClick={goBack}>Retour</button>

                                                </div>
                                            </div>
                                        : <div className='text-left'><button className='btn btn-danger w-100 mb-4' onClick={Disconnect}>Déconnexion</button> </div>

                        : null
                    }

                    {!isAuthenticated ? <Auth setAuth={setIsAuthenticated}/> : null}
                    {
                        isAuthenticated && !section
                            ?
                                <div>
                                    <div className='special-title-admin mb-4'>
                                        Année scolaire 2022 / 2023
                                    </div>

                                    <div className='accueilContainer'>
                                        <div className='row align-items-center mb-4'>
                                            <div className='col-12 col-md-6 mb-4 mb-md-0'>
                                                <img width={'100%'} height={'100%'} src={NewsEcole} />
                                            </div>
                                            <div className='col-12 col-md-6 align-items-center d-flex flex-column'>
                                                <h3 className='text-center w-100'><strong>News de l'école</strong></h3>
                                                <button className='btn btn-info w-50 w-' onClick={() => setCustomSection('news')}>Entrer</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            : null
                    }
                    {
                        isAuthenticated && section === 'albums'
                            ? <Albums schoolName={schoolName}/>
                            : null
                    }
                    {
                        isAuthenticated && section === 'news'
                            ?
                            <div>
                                <div className='special-title-admin mb-4'>
                                    Ressources
                                </div>
                                <div className='ressourcesContainer text-center'>
                                    {
                                        !documentsEcole.length
                                            ?
                                                'Aucun document disponible'
                                            :
                                                <ul className="list-group mb-4 text-left">
                                                    {
                                                        documentsEcole.length
                                                            ? documentsEcole.map((document, index) => {
                                                                if (document?.link)
                                                                    return (
                                                                        <li key={index} className="list-group-item">
                                                                            <div className='row align-items-center'>
                                                                                <div className='col-6'>{document.title}</div>
                                                                                <div className='col-6 text-right'>
                                                                                    <a target="_blank" href={apiURL + document.link}
                                                                                       className="btn btn-primary"
                                                                                       role="button" aria-pressed="true">Télécharger</a>

                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    )
                                                            })
                                                            : null
                                                    }
                                                </ul>
                                        }

                                    <div className='special-title-admin mb-4 mt-4'>
                                        Activités Extra Scolaires  2022/2023
                                    </div>
                                    <div className='activitesContainer d-flex flex-wrap justify-content-center mb-4'>
                                        {
                                            documentsAES.map((document, index) => {
                                                if (document.inscriptionLink || document.informationsLink)
                                                    return (
                                                        <div className='cardContainer m-3 justify-content-center' style={{maxWidth: '40%', minWidth: '25%'}}  key={index}>
                                                            <div className='imgWrapper' style={{height: 'auto', width: '100%'}}>
                                                                <img style={{objectFit: 'cover', objectPosition: 'center'}} width={'100%'} height={'100%'} alt={document.name} src={document.img} />
                                                            </div>
                                                            {/*TITLE*/}
                                                            <h3 className='w-100 text-center p-3'>{document.title}</h3>
                                                            {/*BUTTONS*/}
                                                            {
                                                                <div className='row'>
                                                                    <div className={`${!document.informationsLink || !document.inscriptionLink ? 'col-12' : 'col-6'}`}>
                                                                        {document.informationsLink ? <a className='btn btn-info w-100' target='_blank' href={apiURL +  document.informationsLink}>Infos</a> : null}
                                                                    </div>
                                                                    {
                                                                        document.informationsLink && document.inscriptionLink
                                                                            ?   <div className='col-6'>
                                                                                {document.inscriptionLink ? <a className='btn btn-primary w-100' target='_blank' href={apiURL + document.inscriptionLink}>Inscription</a> : null}

                                                                            </div>
                                                                            : null
                                                                    }

                                                                </div>
                                                            }
                                                        </div>
                                                    )
                                            })
                                        }
                                        {!documentsAES.length ? 'Aucun document disponible' : null}
                                    </div>
                                    <div className='special-title-admin mb-4'>
                                        Activités HAPPYLAND - Le Mercredi
                                    </div>
                                    <div className='activitesContainer d-flex flex-wrap justify-content-center mb-4'>
                                        {
                                            documentsHAPPY.map((document, index) => {
                                                if (document.inscriptionLink || document.informationsLink)
                                                    return (
                                                        <div className='cardContainer m-3 justify-content-center' style={{maxWidth: '40%', minWidth: '25%'}}  key={index}>
                                                            <div className='imgWrapper' style={{height: 'auto', width: '100%'}}>
                                                                <img style={{objectFit: 'cover', objectPosition: 'center'}} width={'100%'} height={'100%'} alt={document.name} src={document.img} />
                                                            </div>
                                                            {/*TITLE*/}
                                                            <h3 className='w-100 text-center p-3'>{document.title}</h3>
                                                            {/*BUTTONS*/}
                                                            {
                                                                <div className='row'>
                                                                    <div className={`${!document.informationsLink || !document.inscriptionLink ? 'col-12' : 'col-6'}`}>
                                                                        {document.informationsLink ? <a className='btn btn-info w-100' target='_blank' href={apiURL +  document.informationsLink}>Infos</a> : null}
                                                                    </div>
                                                                    {
                                                                        document.informationsLink && document.inscriptionLink
                                                                            ?   <div className='col-6'>
                                                                                {document.inscriptionLink ? <a className='btn btn-primary w-100' target='_blank' href={apiURL + document.inscriptionLink}>Inscription</a> : null}

                                                                            </div>
                                                                            : null
                                                                    }

                                                                </div>
                                                            }
                                                        </div>
                                                    )
                                                return null
                                            })
                                        }
                                        {!documentsHAPPY.length ? 'Aucun document disponible' : null}
                                    </div>
                                </div>

                            </div>
                            : null
                    }
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Parents;