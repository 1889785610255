import axios from 'axios';
import authHeader from './auth-header';
const API_URL = process.env.GATSBY_API_URL;

class AdminService {
    sendInputRessources(data, school, directory) {
        let header = authHeader();
        header["Content-Type"] = "multipart/form-data"
        return axios.post(API_URL + `/user/sendInputRessources/${school}/${directory}`, data, {
            headers: header
        });
    }
    uploadPhotos(data, school, year, month, section) {
        let header = authHeader();
        header["Content-Type"] = "multipart/form-data ; application/json"
        return axios.post(API_URL + `/user/uploadPhotos/${school}/${year}/${month}/${section}`, data, {
            headers: header
        });
    }
    getPhotos(school, year, month, section) {
        let header = authHeader();
        header["Content-Type"] = "application/json"
        return axios.get(API_URL + `/parents/getPhotos/${school}/${year}/${month}/${section}`,  {
            headers: header
        });
    }
    removeFile(school, ressourceName, ressourceDirectory){
        let header = authHeader();
        header["Content-Type"] = "application/json"
        return axios.get(API_URL + `/user/removeFile/${school}/${ressourceDirectory}/${ressourceName}`,  {
            headers: header
        });
    }
    removePicture(school, year, month, fileName){
        let header = authHeader();
        header["Content-Type"] = "application/json"
        return axios.get(API_URL + `/user/removePicture/${school}/photos/${year}/${month}/${fileName}`,  {
            headers: header
        });
    }
    eventCalendar_add( params){
        let header = authHeader();
        header["Content-Type"] = "application/json"
        return axios.post(API_URL + `/eventCalendar/add`,  {
            data: {
                params
            }
        }, {
            headers: header,
        });
    } eventCalendar_delete(event_id){
        let header = authHeader();
        header["Content-Type"] = "application/json"
        return axios.post(API_URL + `/eventCalendar/delete`,  {
            event_id: event_id
        }, {
            headers: header
        });
    }
    eventCalendar_get(school_name){
        let header = authHeader();
        header["Content-Type"] = "application/json"
        return axios.get(API_URL + `/eventCalendar/get/${school_name}`,  {
            headers: header,
        });
    }
}

export default new AdminService();