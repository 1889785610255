import React, {useState, useEffect} from 'react';
import AdminService from './../../../../services/admin.service'
import AuthService from "../../../../services/auth.service";
const apiURL = process.env.GATSBY_API_URL;


const deleteIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="black" className="bi bi-x-circle-fill"
         viewBox="0 0 16 16">
        <path
            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
    </svg>
)

const PhotosClasse = ({albumInfos}) => {
    const [year, setYear] = useState('')
    const [chargedPictures, setChargedPictures] = useState([
        {
            month: 'Janvier',
            loading: true,
            pictures: []
        },
        {
            month: 'Fevrier',
            loading: true,
            pictures: []
        },
        {
            month: 'Mars',
            loading: true,
            pictures: []
        },
        {
            month: 'Avril',
            loading: true,
            pictures: []
        },
        {
            month: 'Mai',
            loading: true,
            pictures: []
        },
        {
            month: 'Juin',
            loading: true,
            pictures: []
        },
        {
            month: 'Juillet',
            loading: true,
            pictures: []
        },
        {
            month: 'Aout',
            loading: true,
            pictures: []
        },
        {
            month: 'Septembre',
            loading: true,
            pictures: []
        },
        {
            month: 'Octobre',
            loading: true,
            pictures: []
        },
        {
            month: 'Novembre',
            loading: true,
            pictures: []
        },
        {
            month: 'Decembre',
            loading: true,
            pictures: []
        },


    ])
    const [loading, setLoading] = useState(false)


    /* ----------------- AUTHENTICATION -------------------- */
    useEffect(() => {
        let mounted = true;

        mounted && AuthService.checkTokenAdmin()
            .then(() => mounted && setIsAuthenticated(true))
            .catch(() => mounted && setIsAuthenticated(false))
        return () => mounted = false;

    },[])

    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const deletePicture = async(indexMonth, indexImage, imageLink, monthName) => {
        try {
            await AdminService.removePicture(albumInfos.schoolName, year, monthName, encodeURIComponent(imageLink))
            setChargedPictures((prevState) => {
                return (
                    {
                        ...prevState,
                        [indexMonth]: {
                            ...prevState[indexMonth],
                            pictures: prevState[indexMonth].pictures.filter(e => e !== imageLink)
                        }
                    }
                )

            })
        }
        catch(err) {
            return err
        }

    }

    const yearChoose = async(year) => {
        setYear(year)
        setLoading(true)
        for (let i = 0; i < chargedPictures.length; i++) {
            try {
                let data = await AdminService.getPhotos(albumInfos.schoolName, year, chargedPictures[i].month, albumInfos.className)
                if (data?.data?.links.length) {
                    setChargedPictures((prevState) => {
                        return ({
                            ...prevState,
                            [i]: {
                                ...prevState[i],
                                pictures: data.data.links.filter(x => x)


                            }
                        })
                    })
                }
            } catch(err) {
            }
        }
        setLoading(false);
    }


    const DisplayPictures = () => {
        if (Object.values(chargedPictures).length)
            return Object.values(chargedPictures).map((month, indexMonth) => {
                return (
                    <div  key={indexMonth}>
                        <h4><strong>{month.month}</strong></h4>
                        <div

                             style={{
                                 display: 'flex',
                                 justifyContent: 'left',
                                 alignItems: 'center',
                                 flexDirection: 'row',
                                 overflowX: 'scroll',
                                 marginBottom: '2em',
                                 marginTop: '2em',
                                 maxHeight: '350px'

                             }}
                        >
                            {month.pictures.map((image, indexImage) => {
                                if (image.length) {
                                    return (
                                        <div style={{
                                            height: '300px',
                                            minWidth: '400px',
                                            margin: '1em',
                                            position: 'relative'
                                        }}
                                             key={indexImage}
                                        >
                                            {isAuthenticated ?<span
                                                style={{position: 'absolute', right: '10px', top: '10px', cursor: 'pointer'}}
                                                onClick={() => deletePicture(indexMonth, indexImage, image, month.month)}
                                            >
                                                {deleteIcon}
                                            </span> : null}
                                            <a href={apiURL + image} target={'_blank'}>
                                                <img
                                                    style={{
                                                        objectFit: 'cover',
                                                        width: '100%',
                                                        height: '100%',
                                                        borderRadius: '0.5em',
                                                        boxShadow: '5px 5px 15px rgba(0,0,0,0.1)'
                                                    }}
                                                    alt='image'
                                                    src={apiURL + image}

                                                /></a>
                                        </div>
                                    )
                                }
                                return null
                            })}
                            {month.pictures.length === 0 ? 'Aucun résultat' : ''}
                        </div>
                    </div>
                )
            })
        return null
    }

    return (
        <div>
            <div className='col-12'>
                <h3 className='mt-5'>{albumInfos.classNameTitle}</h3>
                <h5 className='mt-1'>Selectionnez une année</h5>

                <div style={{display: 'flex', justifyContent: 'left', alignItems: 'center'}}>
                    <button
                        className='btn btn-lg btn-primary m-1'
                        onClick={() => yearChoose('2019')}
                    >
                        2019
                    </button>
                    <button
                        className='btn btn-lg btn-primary m-1'
                        onClick={() => yearChoose('2020')}
                    >
                        2020
                    </button>
                    <button
                        className='btn btn-lg btn-primary m-1'
                        onClick={() => yearChoose('2021')}

                    >
                        2021
                    </button>
                </div><br /><br />
                {
                    loading
                        ?
                            <div className='w-100 text-center pt-5'>
                                <div className="spinner-border text-primary" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        :
                            null
                }
                {year  && !loading ?  <DisplayPictures /> : null}

            </div>
        </div>
    )
}

export default PhotosClasse