import axios from 'axios';
import authHeader from './auth-header';
const API_URL = process.env.GATSBY_API_URL;

class AdminService {
    getAvailableDocuments(school, directory) {
        let header = authHeader();
        header["Content-Type"] = "multipart/form-data"
        return axios.get(API_URL + `/parents/getAvailableDocuments/${school}/${directory}`,{
            headers: header
        });
    }
}

export default new AdminService();