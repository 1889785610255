import axios from "axios";
import authHeader from './auth-header';
const API_URL = process.env.GATSBY_API_URL;

class AuthService {
    login(username, password) {
        return axios
            .post(API_URL + "/auth/signin", { username, password })
            .then((response) => {
                if (response.data.accessToken) {
                    localStorage.setItem("user", JSON.stringify(response.data));
                }

                return response.data;
            });
    }
    logout() {
        localStorage.removeItem("user");
    }

    checkTokenAdmin() {
        return new Promise((resolve, reject) => {
            return axios.get(
                API_URL + '/auth/checkTokenAdmin',
                {headers: authHeader()}
            )
                .then(() => resolve())
                .catch(() => reject())
        })

    }

    checkTokenParents() {
        return new Promise((resolve, reject) => {
            return axios.get(
                API_URL + '/auth/checkTokenParents',
                {headers: authHeader()}
            )
                .then(() => resolve())
                .catch(() => reject())
        })

    }
}

export default new AuthService();