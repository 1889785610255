import React, {useState} from 'react';
import PhotosClasse from "./PhotosClasse/index";
const Albums = ({schoolName}) => {
    const [albumInfos, setAlbumInfos] = useState({
        schoolName: schoolName,
        className: '',
        classNameTitle: ''
    })

    const handleClickButton = (event, title) => {

        if (event.target.name && title){
            setAlbumInfos((prevState => ({
                ...prevState,
                className: event.target.name,
                classNameTitle: title
            })))
        }
    }
    return (
        <div>
            {
                albumInfos.className && albumInfos.classNameTitle
                ?
                    <PhotosClasse albumInfos={albumInfos} />
                :
                    <div className='row'>
                        <div className='col-12'>
                            <div className='special-title-admin' >
                               Événements école
                            </div>
                            <ul className="list-group mb-3">
                                <li className="list-group-item">
                                    <div className='row align-items-center'>
                                        <div className='col-6'>Événements école</div>
                                        <div className='col-6 text-right'>
                                            <button
                                                className='btn btn-primary'
                                                name='evenements-ecole'
                                                onClick={(e) => handleClickButton(e, 'Événements école')}
                                            >
                                                Voir
                                            </button>{' '}
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className='col-12 col-md-4'>
                            <div className='special-title-admin' >
                                Photos maternelle
                            </div>
                            <ul className="list-group mb-3">
                                <li className="list-group-item">
                                    <div className='row align-items-center'>
                                        <div className='col-6'>Classe des Poussins-Écureuils</div>
                                        <div className='col-6 text-right'>
                                            <button
                                                className='btn btn-primary'
                                                name='poussins-ecureuils'
                                                onClick={(e) => handleClickButton(e, 'Classe des Poussins-Écureuils')}
                                            >
                                                Voir
                                            </button>{' '}
                                        </div>
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <div className='row align-items-center'>
                                        <div className='col-6'>Classe des Écureuils</div>
                                        <div className='col-6 text-right'>
                                            <button
                                                className='btn btn-primary'
                                                name="ecureuils"
                                                onClick={(e) => handleClickButton(e, 'Classe des Écureuils')}
                                            >
                                                Voir
                                            </button>{' '}
                                        </div>
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <div className='row align-items-center'>
                                        <div className='col-6'>Classe des Pandas</div>
                                        <div className='col-6 text-right'>
                                            <button
                                                className='btn btn-primary'
                                                name="pandas"
                                                onClick={(e) => handleClickButton(e, 'Classe des Pandas')}
                                            >
                                                Voir
                                            </button>{' '}
                                        </div>
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <div className='row align-items-center'>
                                        <div className='col-6'>Classe des Koalas</div>
                                        <div className='col-6 text-right'>
                                            <button
                                                className='btn btn-primary'
                                                name="koalas"
                                                onClick={(e) => handleClickButton(e, 'Classe des Koalas')}
                                            >
                                                Voir
                                            </button>{' '}
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className='col-12 col-md-4'>
                            <div className='special-title-admin'>
                                Photos primaire
                            </div>
                            <ul className="list-group mb-3">
                                <li className="list-group-item">
                                    <div className='row align-items-center'>
                                        <div className='col-6'>Classe des CP A</div>
                                        <div className='col-6 text-right'>
                                            <button
                                                className='btn btn-primary'
                                                name="cp-a"
                                                onClick={(e) => handleClickButton(e, 'Classe des CP A')}
                                            >
                                                Voir
                                            </button>{' '}
                                        </div>
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <div className='row align-items-center'>
                                        <div className='col-6'>Classe des CP B</div>
                                        <div className='col-6 text-right'>
                                            <button
                                                className='btn btn-primary'
                                                name="cp-b"
                                                onClick={(e) => handleClickButton(e, 'Classe des CP B')}
                                            >
                                                Voir
                                            </button>{' '}
                                        </div>
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <div className='row align-items-center'>
                                        <div className='col-6'>Classe des CE1</div>
                                        <div className='col-6 text-right'>
                                            <button
                                                className='btn btn-primary'
                                                name="ce1"
                                                onClick={(e) => handleClickButton(e, 'Classe des CE1')}
                                            >
                                                Voir
                                            </button>{' '}
                                        </div>
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <div className='row align-items-center'>
                                        <div className='col-6'>Classe des CE2 A</div>
                                        <div className='col-6 text-right'>
                                            <button
                                                className='btn btn-primary'
                                                name="ce2-a"
                                                onClick={(e) => handleClickButton(e, 'Classe des CE2 A')}
                                            >
                                                Voir
                                            </button>{' '}
                                        </div>
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <div className='row align-items-center'>
                                        <div className='col-6'>Classe des CE2 B</div>
                                        <div className='col-6 text-right'>
                                            <button
                                                className='btn btn-primary'
                                                name="ce2-b"
                                                onClick={(e) => handleClickButton(e, 'Classe des CE2 B')}
                                            >
                                                Voir
                                            </button>{' '}
                                        </div>
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <div className='row align-items-center'>
                                        <div className='col-6'>Classe des CM1</div>
                                        <div className='col-6 text-right'>
                                            <button
                                                className='btn btn-primary'
                                                name="cm1"
                                                onClick={(e) => handleClickButton(e, 'Classe des CM1')}
                                            >
                                                Voir
                                            </button>{' '}
                                        </div>
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <div className='row align-items-center'>
                                        <div className='col-6'>Classe des CM2</div>
                                        <div className='col-6 text-right'>
                                            <button
                                                className='btn btn-primary'
                                                name="cm2"
                                                onClick={(e) => handleClickButton(e, 'Classe des CM2')}
                                            >
                                                Voir
                                            </button>{' '}
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className='col-12 col-md-4'>
                            <div className='special-title-admin'>
                                Photos Mini-Club
                            </div>
                            <ul className='list-group'>
                                <li className="list-group-item">
                                    <div className='row align-items-center'>
                                        <div className='col-6'>Baby Club</div>
                                        <div className='col-6 text-right'>
                                            <button
                                                className='btn btn-primary'
                                                name="baby-club"
                                                onClick={(e) => handleClickButton(e, 'Baby Club')}
                                            >
                                                Voir
                                            </button>{' '}
                                        </div>
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <div className='row align-items-center'>
                                        <div className='col-6'>Loustics</div>
                                        <div className='col-6 text-right'>
                                            <button
                                                className='btn btn-primary'
                                                name="loustics"
                                                onClick={(e) => handleClickButton(e, 'Loustics')}

                                            >
                                                Voir
                                            </button>{' '}
                                        </div>
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <div className='row align-items-center'>
                                        <div className='col-6'>Maous Costauds</div>
                                        <div className='col-6 text-right'>
                                            <button
                                                className='btn btn-primary'
                                                name="maous-costauds"
                                                onClick={(e) => handleClickButton(e, 'Maous Costauds')}
                                            >
                                                Voir
                                            </button>{' '}
                                        </div>
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <div className='row align-items-center'>
                                        <div className='col-6'>Les stages</div>
                                        <div className='col-6 text-right'>
                                            <button
                                                className='btn btn-primary'
                                                name="stages"
                                                onClick={(e) => handleClickButton(e, 'Les stages')}
                                            >
                                                Voir
                                            </button>{' '}
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
            }
        </div>
    )
}

export default Albums